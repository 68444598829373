/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: MyriadPro;
  src: url("/assets/myriadpro-regular.woff");
}

@font-face {
  font-family: Gotham;
  src: url("/assets/gotham-medium.woff");
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: MyriadPro, sans-serif;

  * {
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

@import "vars";
@include angular-material-theme($candy-app-theme);
